import * as R from 'ramda';

const getCommentsCode = state => R.pathOr([], ['lsamsNotes', 'commentsCode'], state);
const inProgress = state => R.pathOr(false, ['lsamsNotes', 'inProgress'], state);

const selectors = {
  getCommentsCode,
  inProgress,
};

export default selectors;
