import React, { useState, useEffect } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PropTypes } from 'prop-types';
import './Onboard.css';

const Validation = ({ getExceptionData }) => {
  const [validRecordsCount, setValidRecordsCount] = useState(0);
  const [invalidRecordsCount, setInvalidRecordsCount] = useState(0);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    // Count valid and invalid records
    let validCount = 0;
    let invalidCount = 0;
    getExceptionData.forEach((record) => {
      if (record && record['Critical Severity Exceptions'].length === 0) {
        validCount += 1;
      } else {
        invalidCount += 1;
      }
    });

    // Update state variables
    setValidRecordsCount(validCount);
    setInvalidRecordsCount(invalidCount);
    setHasErrors(invalidCount > 0);
  }, [getExceptionData]);

  return (
    <div styleName="validation-item">
      {hasErrors ? (
        <>
          <ErrorIcon style={{ color: 'red', fontSize: 'small' }} />
          <span styleName="validation-typography">
            {validRecordsCount}
            {' '}
            Valid Records &
            {' '}
          </span>
          <span styleName="validation-typography">
          &nbsp;
            {invalidRecordsCount}
            {' '}
            Invalid Records Found
          </span>
        </>
      ) : (
        <>
          <CheckCircleIcon style={{ color: 'green', fontSize: 'small' }} />
          <span styleName="validation-typography">
            {validRecordsCount}
            {' '}
            Valid Records Found
          </span>
        </>
      )}
    </div>
  );
};

Validation.propTypes = {
  getExceptionData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default Validation;
