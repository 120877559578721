/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { operations } from 'ducks/boardingTemplate';
import { selectors as boardingTemplateSelectors } from 'ducks/boardingTemplate';
import { selectors as loginSelectors } from 'ducks/login';
import {
  templateDataFileName, exceptionDataFileName, exceptionBtn, template, uploadedTemplate,
} from 'constants/BoardingTemplate';
import * as R from 'ramda';
import './DownloadExcel.css';
import * as moment from 'moment';

function DownloadExcel({
  downloadFile, onDownloadComplete, isUploadCompleted,
  onBoardData, exceptionData, user, processedFileData, fileUploadData,
  uploadedData,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [buttonText, setButtonText] = useState(template);

  const getBoardingType = (excData) => {
    let boardingType;
    if (excData.InFlight && excData['Pre Approved']) {
      boardingType = 'Inflight, Pre Approved';
    } else if (excData.InFlight) {
      boardingType = 'Inflight';
    } else {
      boardingType = 'Pre Approved';
    }
    return boardingType;
  };
  const handleExcpDownload = () => {
    const fileName = exceptionDataFileName;
    let data;
    if (isUploadCompleted) {
      const formattedExceptionList = exceptionData.map((excData) => {
        const consolidatedData = [];

        const formattedData = {
          UploadDatetime: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          RequestorName: user.userDetails.name,
          FileName: processedFileData.fileName,
          ExceptionTriggeredDatetime: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          RecordNumber: excData['Row Number'],
          LoanNumber: excData['Loan ID'],
          'LSAMS UPB (at time uploaded)': excData['Current UPB'],
          CaseType: excData['Case Type'],
          BoardingPhase: excData.Phase,
          BoardingType: getBoardingType(excData),
          EvalType: excData['Eval Type'],
        };

        const exceptions = {};

        if (!R.isNil(excData['Critical Severity Exceptions']) && !R.isEmpty(excData['Critical Severity Exceptions'])) {
          exceptions.ExceptionId = (excData['Critical Severity Exceptions'] || []).map(exc => exc.exceptionId).join(';');
          exceptions.ExceptionSeverity = 'Critical';
          exceptions.ExceptionName = (excData['Critical Severity Exceptions'] || []).map(exc => exc.title).join(';');
          exceptions.ExceptionMessage = (excData['Critical Severity Exceptions'] || []).map(exc => exc.message).join(';');

          consolidatedData.push(Object.assign({}, formattedData, exceptions));
        }

        if (!R.isNil(excData['Medium Severity Exceptions']) && !R.isEmpty(excData['Medium Severity Exceptions'])) {
          exceptions.ExceptionId = (excData['Medium Severity Exceptions'] || []).map(exc => exc.exceptionId).join(';');
          exceptions.ExceptionSeverity = 'Medium';
          exceptions.ExceptionName = (excData['Medium Severity Exceptions'] || []).map(exc => exc.title).join(';');
          exceptions.ExceptionMessage = (excData['Medium Severity Exceptions'] || []).map(exc => exc.message).join(';');

          consolidatedData.push(Object.assign({}, formattedData, exceptions));
        }

        if (!R.isNil(excData['Low Severity Exceptions']) && !R.isEmpty(excData['Low Severity Exceptions'])) {
          exceptions.ExceptionId = (excData['Low Severity Exceptions'] || []).map(exc => exc.exceptionId).join(';');
          exceptions.ExceptionSeverity = 'Low';
          exceptions.ExceptionName = (excData['Low Severity Exceptions'] || []).map(exc => exc.title).join(';');
          exceptions.ExceptionMessage = (excData['Low Severity Exceptions'] || []).map(exc => exc.message).join(';');

          consolidatedData.push(Object.assign({}, formattedData, exceptions));
        }

        return consolidatedData;
      });
      data = R.flatten(formattedExceptionList);
    }
    const payload = {
      fileName,
      data,
    };
    downloadFile(payload);
    setShowPopup(true);
    onDownloadComplete();
  };

  const handleDownload = () => {
    const fileName = templateDataFileName;
    let data = onBoardData;
    if (buttonText === uploadedTemplate) {
      data = uploadedData;
    }
    const payload = {
      fileName,
      data,
    };
    downloadFile(payload);
    setShowPopup(true);
    onDownloadComplete();
  };

  useEffect(() => {
    if (isUploadCompleted) {
      setButtonText(uploadedTemplate);
    } else {
      setButtonText(template);
    }
  }, [isUploadCompleted]);

  return (
    <div>
      <div styleName="button-container">
        {isUploadCompleted && (
        <button disabled={R.isEmpty(exceptionData)} onClick={handleExcpDownload} styleName="template-button" type="button">
          <img alt="Exception" src="/static/img/download.png" style={{ width: '18px' }} />
          {exceptionBtn}
        </button>
        )}
        <button disabled={R.isEmpty(fileUploadData)} onClick={handleDownload} styleName={R.isEmpty(fileUploadData) ? 'button-disabled' : 'template-button'} type="button">
          <img alt="upload" src="/static/img/download.png" style={{ width: '18px' }} />
          {buttonText}
        </button>
      </div>
      {showPopup && (
        <div styleName="popup">
          <div styleName="popup-content">
            <img alt="upload" src="/static/img/success.svg" style={{ width: '15px', marginRight: '5px' }} />
            <p style={{ display: 'inline' }}>Download Complete</p>
            <span
              onClick={() => setShowPopup(false)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  setShowPopup(false);
                }
              }}
              role="button"
              styleName="close"
              tabIndex={0}
            >
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

DownloadExcel.propTypes = {
  downloadFile: PropTypes.func.isRequired,
  exceptionData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fileUploadData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isUploadCompleted: PropTypes.bool.isRequired,
  onBoardData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDownloadComplete: PropTypes.func.isRequired,
  processedFileData: PropTypes.shape().isRequired,
  uploadedData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    skills: PropTypes.objectOf(PropTypes.array).isRequired,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => (
  {
    onBoardData: boardingTemplateSelectors.getOnBoardData(state),
    exceptionData: boardingTemplateSelectors.getExceptionData(state),
    user: loginSelectors.getUser(state),
    processedFileData: boardingTemplateSelectors.getProcesseFiledData(state),
    uploadedData: boardingTemplateSelectors.getUploadedData(state),
  }
);

const mapDispatchToProps = dispatch => ({
  downloadFile: operations.downloadFile(dispatch),
});

const DownloadExcelContainer = connect(mapStateToProps, mapDispatchToProps)(DownloadExcel);

export default withRouter(DownloadExcelContainer);
