import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Checkbox,
  TablePagination, Paper, IconButton, Menu, MenuItem, Box, Tooltip, TextField, Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
  TYPE_LINK, TYPE_SEARCH, TYPE_CLEAR,
} from '../../../constants/indexer';
import './MUITable.css';

function MUITable(props) {
  const {
    columns, indexerData, handleLink, handleChange,
  } = props;
  const data = R.propOr([], 'indexerDetailsList', indexerData);
  const processData = () => data.map((item) => {
    let newObject = {};
    columns.forEach(
      (obj) => {
        newObject = R.assoc(
          obj.name,
          obj.cellFormat ? obj.cellFormat(item[obj.name]) : item[obj.name],
          newObject,
        );
        return newObject;
      },
    );
    return newObject;
  });
  const [currentTableFilter, setCurrentTableFilter] = React.useState({
    initialData: processData(),
    filteredData: processData(),
    columnFilterIndex: R.pluck(
      'name',
      R.filter(R.allPass([R.pathEq(['options', 'filter'], true)]))(columns),
    ),
  });

  const [searchFilter, setSearchFilter] = React.useState({
    searchType: '',
    searchValue: '',
    isFilterActive: false,
    activeFilter: '',
  });

  useEffect(() => {
    setCurrentTableFilter({
      initialData: processData(),
      filteredData: processData(),
      columnFilterIndex: R.pluck(
        'name',
        R.filter(R.allPass([R.pathEq(['options', 'filter'], true)]))(columns),
      ),
    });
    if (indexerData.isActive) {
      const filter = document.getElementById(`filter-${indexerData.searchType}`);
      if (filter.src.match('.png')) {
        filter.src = '/static/img/filter.svg';
      } else {
        filter.src = '/static/img/filter.png';
      }
      setSearchFilter({
        isFilterActive: true,
        activeFilter: indexerData.searchType,
      });
    }
  }, [data]);

  useEffect(() => {
    let newState = {};
    columns.forEach((item) => {
      if (item.options !== undefined && item.options.filter) {
        newState = R.assocPath(['filters', item.name], [], newState);
        newState[`${item.name}Filter`] = null;
        newState[`${item.name}Checkbox`] = {};
        R.without(['', null], R.uniq(R.pluck([item.name], currentTableFilter.initialData))).forEach(
          (value) => { newState[`${item.name}Checkbox`][value] = Boolean(false); },
        );
      }
    });

    setCurrentTableFilter({
      ...newState,
      ...currentTableFilter,
    });
  }, [currentTableFilter.filteredData]);

  const handleFilterCheckbox = (event, stateRef, dropdownValue) => {
    const filterClone = currentTableFilter.filters;
    const checkState = `${stateRef}Checkbox`;
    const checkValue = dropdownValue || event.target.value;
    const checkboxBoolSwitch = event.target.checked !== undefined
      ? Boolean(event.target.checked)
      : !currentTableFilter[checkState][checkValue];

    const filter = document.getElementById(`filter-${stateRef}`);
    const handleTableFilter = () => {
      setCurrentTableFilter(prevState => ({
        ...prevState,
        [checkState]: {
          ...prevState[checkState],
          [checkValue]: checkboxBoolSwitch,
        },
      }));
    };

    if (filter.src.match('.png')) {
      filter.src = '/static/img/filter.svg';
    } else {
      filter.src = '/static/img/filter.png';
    }

    if (
      currentTableFilter[checkState]
      && currentTableFilter[checkState][checkValue] !== undefined
    ) {
      if (event.target.checked !== undefined && !dropdownValue) {
        handleTableFilter();
      } else {
        handleTableFilter();
      }
    }

    if (currentTableFilter.filters[stateRef]) {
      const arrayState = [...currentTableFilter.filters[stateRef]];
      const index = arrayState.indexOf(checkValue);
      if (index !== -1 && !checkboxBoolSwitch) {
        const filteredArray = arrayState.filter(item => item !== checkValue);
        filterClone[stateRef] = filteredArray;
      } else if (index === -1 && checkboxBoolSwitch) {
        filterClone[stateRef].push(checkValue);
      }
    }

    setCurrentTableFilter(prevState => ({
      ...prevState,
      filters: filterClone,
    }));

    // USE INITIAL DATA FOR FILTERING includes ramda
    let resetFilter = true;
    let initialDataClone = currentTableFilter.initialData;
    currentTableFilter.columnFilterIndex.forEach((item) => {
      if (R.length(currentTableFilter.filters[item]) > 0) {
        initialDataClone = R.innerJoin(
          (initialDataObject, tableFilters) => initialDataObject[item] === (['loanId', 'evalId'].includes(item) ? +tableFilters : tableFilters),
          initialDataClone,
          filterClone[item],
        );
        setCurrentTableFilter(prevState => ({
          ...prevState,
          filteredData: initialDataClone,
        }));
        if (resetFilter) {
          resetFilter = false;
        }
      }
    });

    // RESET TABLE FILTERS IF NO OPTIONS ARE CHOSEN
    if (resetFilter) {
      setCurrentTableFilter(prevState => ({
        ...prevState,
        filteredData: currentTableFilter.initialData,
      }));
    }
  };

  const handleClick = (event, stateRef) => {
    setCurrentTableFilter(prevState => ({
      ...prevState,
      [stateRef]: event.currentTarget,
    }));
    setSearchFilter(prev => ({
      ...prev,
      searchValue: '',
    }));
  };

  const handleCloseMenu = (event, stateRef) => {
    setCurrentTableFilter(prevState => ({
      ...prevState,
      [stateRef]: null,
    }));
  };

  const handleSearchBtn = (event, stateRef) => {
    handleLink(searchFilter, TYPE_SEARCH);
    handleCloseMenu(event, `${stateRef}Filter`);
  };

  const handleChangePage = (event, newPage) => {
    handleChange(newPage, R.propOr(10, 'pageSize', indexerData));
  };

  const handleChangeRowsPerPage = (event) => {
    handleChange(0, +event.target.value);
  };

  const handleClearFilter = (event, stateRef) => {
    const filter = document.getElementById(`filter-${stateRef}`);
    if (filter.src.match('.svg')) {
      filter.src = '/static/img/filter.png';
    }
    setSearchFilter(prev => ({
      ...prev,
      isFilterActive: false,
      activeFilter: null,
    }));
    handleLink(null, TYPE_CLEAR);
  };


  return (
    <Box>
      <TableContainer
        component={Paper}
        elevation={0}
      >
        <Table aria-label="simple table" styleName="table-header">
          <TableHead>
            <TableRow>
              {columns.map(
                ({
                  name: itemName, align, label, options,
                }, i) => itemName && (
                <TableCell
                  key={itemName || i}
                  align={align}
                  style={{
                    // width: `${width}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {label}
                  {options
                      && options.filter
                      && options.toolTip ? (
                        <>
                          <Tooltip
                            id={`${itemName}Filter`}
                            onClick={event => handleClick(event, `${itemName}Filter`)}
                            title={
                              options.toolTip && options.toolTip.title
                                ? options.toolTip.title
                                : ''
                            }
                          >
                            <IconButton>
                              <img
                                alt="filter"
                                id={`filter-${itemName}`}
                                src="/static/img/filter.png"
                                style={{ width: '0.85rem' }}
                              />
                            </IconButton>
                          </Tooltip>
                          {searchFilter.isFilterActive
                              && itemName === searchFilter.activeFilter && (
                                <Tooltip onClick={event => handleClearFilter(event, itemName)} title="clear Search results">
                                  <IconButton styleName="icon-btn-search">
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                          )}
                        </>
                    ) : null}

                  <Menu
                    anchorEl={
                          currentTableFilter[`${itemName}Filter`] || null
                        }
                    id={`${itemName}Filter`}
                    keepMounted
                    onClose={event => handleCloseMenu(event, `${itemName}Filter`)
                        }
                    open={Boolean(
                      currentTableFilter[`${itemName}Filter`],
                    )}
                  >
                    {options && options.searchFilter && (
                      <MenuItem
                        key="No_Filters"
                        styleName="search-container"
                      >
                        <div styleName="search-field">
                          <TextField
                            onChange={(e) => {
                              const { value } = e.target;
                              setSearchFilter({ searchType: itemName, searchValue: value });
                            }}
                            placeholder={`Enter Valid ${itemName}`}
                            size="small"
                            value={searchFilter.searchValue ? searchFilter.searchValue : ''}
                            variant="outlined"
                          />
                          <Button
                            color="primary"
                            disabled={!searchFilter.searchValue}
                            onClick={(event) => {
                              handleSearchBtn(event, itemName);
                            }}
                            variant="contained"
                          >
                            <SearchIcon fontSize="small" />
                          </Button>
                        </div>
                      </MenuItem>
                    )}
                    <div>
                      {R.without(
                        ['', null],
                        R.uniq(
                          R.pluck(
                            [itemName],
                            currentTableFilter.filteredData,
                          ),
                        ),
                      ).length > 0
                        ? R.without(
                          ['', null],
                          R.uniq(
                            R.pluck(
                              [itemName],
                              currentTableFilter.filteredData,
                            ),
                          ),
                        ).map(value => (
                          <div styleName="filter-list">
                            <Checkbox
                              checked={
                              currentTableFilter[`${itemName}Checkbox`]
                                && currentTableFilter[`${itemName}Checkbox`][
                                  value
                                ]
                                ? currentTableFilter[
                                  `${itemName}Checkbox`
                                ][value]
                                : null
                            }
                              onClick={event => handleFilterCheckbox(event, itemName, null)
                            }
                              value={value || ''}
                            />
                            <MenuItem
                              key={`${itemName}_${value}`}
                              onClick={event => handleFilterCheckbox(event, itemName, value)
                            }
                              style={{
                                paddingLeft: 0,
                                backgroundColor: 'transparent',
                              }}
                            >
                              {value || null}
                            </MenuItem>
                          </div>
                        ))
                        : (
                          <MenuItem
                            key="No_Filters"
                            style={{
                              backgroundColor: 'transparent',
                            }}
                          >
                          No filters
                          </MenuItem>
                        )}
                    </div>
                  </Menu>
                </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableFilter.filteredData.length > 0
              ? currentTableFilter.filteredData.map((row, index) => (
                <TableRow hover>
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell
                      align="left"
                      id={`${key}_${value}`}
                      onClick={() => {
                        handleLink(currentTableFilter.filteredData[index], TYPE_LINK);
                      }}
                      // style={{ width: `${columns.find(({ name }) => name === key).width}` }}
                    >
                      {value || ''}
                    </TableCell>
                  ))}
                </TableRow>
              )) : (
                <TableRow hover>
                  <TableCell
                    align="center"
                    colSpan={6}
                  >
                    <Typography color="action" styleName="error-msg" variant="h5">No Record Found</Typography>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {!R.isNil(indexerData.pageNumber) ? (
        <div styleName="pagination-container">
          <TablePagination
            component="div"
            count={R.propOr(0, 'totalElements', indexerData)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={R.propOr(0, 'pageNumber', indexerData)}
            rowsPerPage={R.propOr(10, 'pageSize', indexerData)}
            rowsPerPageOptions={[10, 50, 100, 200]}
          />
        </div>
      ) : (
        <div styleName="spacer" />
      )}
    </Box>
  );
}

MUITable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
    width: PropTypes.number,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLink: PropTypes.func.isRequired,
  indexerData: PropTypes.shape().isRequired,
};

export default MUITable;
