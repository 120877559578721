import React, { useState, useEffect } from 'react';
import ContentHeader from 'components/ContentHeader';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations, selectors as boardingTemplateSelectors } from 'ducks/boardingTemplate';
import { selectors, operations as dashboardOperations } from 'ducks/dashboard';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/BoardingTemplate';
import SweetAlertBox from 'components/SweetAlertBox';
import * as R from 'ramda';
import LeftPane from './LeftPane/LeftPane';
import './BoardingTemplatePage.css';
import TabPanel from './TabPanel/TabPanel';
import ReUpload from './UploadFilesModal/ReUpload';
import RightPane from './RightPane/RightPane';
import OnBoard from './Onboard/OnBoard';
import History from './History/History';


const BoardingTemplatePage = (
  {
    fetchBoardingTemplateHistory, openSweetAlert, resultOperation, closeSweetAlert,
    boardingTemplateHistory, onBoardTemplate, getExceptionData, getOnBoardData,
    getProcesseFiledData, validateBoardingTemplateOperation, initiateImportProcessOperation,
    setCaseTypesOperation, caseTypeOptions, setBoardingPhasesOperation, boardingPhaseOptions,
  },
) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isOnboardEnabled, setIsOnboardEnabled] = useState(false);
  const [isUploadCompleted, handleUploadState] = useState(false);
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [selectedPhase, setSelectedPhase] = useState('');
  const [inFlightStatus, setInFlightStatus] = useState(true);
  const [preApprovedStatus, setPreApprovedStatus] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [loanIds, setLoanIds] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [initialValue, setInitialValue] = useState({
    loanIds: '',
    selectedCaseType: '',
    selectedPhase: '',
    preApprovedStatus: false,
    inFlightStatus: true,
  });
  const [filter, setFilters] = useState({
    fromDate: '',
    toDate: '',
    fileName: '',
    user: '',
  });
  const handleTabSelect = () => {
    const index = tabIndex ? 0 : 1;
    setTabIndex(index);
  };

  const handleClose = () => {
    closeSweetAlert();
  };

  const callOnBoardTemplate = () => {
    const hasChanged = (
      [loanIds, selectedCaseType, selectedPhase, preApprovedStatus, inFlightStatus].some(
        (value, index) => value !== Object.values(initialValue)[index],
      )
    );
    setIsOnboardEnabled(true);
    if (hasChanged) {
      onBoardTemplate({
        loanNumbers: loanIds.trim().replace(/\n/g, ',').split(',').map(s => parseInt(s.trim(), 10)),
        caseType: selectedCaseType,
        boardingPhase: selectedPhase,
        preApprovedFlag: preApprovedStatus,
        inFlightFlag: inFlightStatus,
      });
      handleUploadState(false);
      setInitialValue({
        loanIds, selectedCaseType, selectedPhase, preApprovedStatus, inFlightStatus,
      });
    }
  };

  const onGo = () => {
    // const payload = buildTemplateDatForValidation();

    const loanNumbers = (R.filter(id => !R.isEmpty(id), loanIds.trim().replace(/\n/g, ',').split(','))).map(s => parseInt(s.trim(), 10));
    const loanSet = new Set(loanNumbers);
    let isValidCalltoBoard;
    if (loanNumbers.length !== loanSet.size) {
      const sweetAlertPayload = {
        status: 'There are duplicate Loan Numbers. Please correct and resubmit',
        level: 'Error',
        showConfirmationButton: true,
      };
      openSweetAlert(sweetAlertPayload);
      isValidCalltoBoard = 0;
    }
    if (loanSet.size > 500) {
      const sweetAlertPayload = {
        status: 'Limit exceeded, please enter up to 500 ID(s)',
        level: 'Error',
        showConfirmationButton: true,
      };
      openSweetAlert(sweetAlertPayload);
      isValidCalltoBoard = 0;
    }
    if (isValidCalltoBoard !== 0) {
      callOnBoardTemplate();
    }
  };
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    if (tabIndex === 1) {
      fetchBoardingTemplateHistory(0, DEFAULT_ROWS_PER_PAGE, {}, {});
      setHistoryData(boardingTemplateHistory);
    }
  }, [tabIndex]);

  useEffect(() => {
    setCaseTypesOperation();
  }, []);

  useEffect(() => {
    fetchBoardingTemplateHistory(0, DEFAULT_ROWS_PER_PAGE, filter, {});
    setHistoryData(boardingTemplateHistory);
  }, [filter]);

  useEffect(() => {
    setIsButtonEnabled(isOnboardEnabled && tabIndex === 0);
  }, [isOnboardEnabled, tabIndex]);

  const setFilterData = (data) => {
    setFilters({
      fromDate: data.startDate,
      toDate: data.endDate,
      fileName: data.selectedFile === 'All' ? '' : data.selectedFile,
      user: data.selectedUser === 'All' ? '' : data.selectedUser,
    });
  };

  const renderAlert = (
    <SweetAlertBox
      confirmButtonColor="#004261"
      message={resultOperation.status}
      onConfirm={handleClose}
      show={resultOperation.isOpen}
      showConfirmButton={resultOperation.showConfirmButton}
      title={resultOperation.title}
      type={resultOperation.level}
    />
  );

  return (
    <>
      <ContentHeader title="Eval/Case Boarding Template" />
      <Grid container style={{ backgroundColor: 'white' }} styleName="loan-activity">
        {tabIndex === 0
      && (
      <Grid item style={{ padding: '10px' }} xs={2}>
        {renderAlert}
        <LeftPane
          boardingPhaseOptions={boardingPhaseOptions}
          caseTypeOptions={caseTypeOptions}
          inFlightStatus={inFlightStatus}
          isSubmitDisabled={isSubmitDisabled}
          loanIds={loanIds}
          onGo={onGo}
          preApprovedStatus={preApprovedStatus}
          selectedCaseType={selectedCaseType}
          selectedPhase={selectedPhase}
          setBoardingPhasesOperation={setBoardingPhasesOperation}
          setInFlightStatus={setInFlightStatus}
          setIsSubmitDisabled={setIsSubmitDisabled}
          setLoanIds={setLoanIds}
          setPreApprovedStatus={setPreApprovedStatus}
          setSelectedCaseType={setSelectedCaseType}
          setSelectedPhase={setSelectedPhase}

        />
      </Grid>
      )}
        <Grid item style={{ padding: '10px' }} xs={10}>
          <TabPanel
            fileUploadData={getOnBoardData}
            getExceptionData={getExceptionData}
            getProcesseFiledData={getProcesseFiledData}
            handleUploadState={handleUploadState}
            initiateImportProcessOperation={initiateImportProcessOperation}
            isButtonEnabled={isButtonEnabled}
            isOnboardEnabled={isOnboardEnabled}
            isUploadCompleted={isUploadCompleted}
            onTabSelect={handleTabSelect}
            selectedTab={tabIndex}
            setIsSubmitDisabled={setIsSubmitDisabled}
            tabNames={['On-Board', 'History']}
            validateBoardingTemplateOperation={validateBoardingTemplateOperation}
          />
          {tabIndex === 0 && !isOnboardEnabled && (
            <ReUpload />
          )}

          {tabIndex === 0 && isOnboardEnabled && (
            <>
              <OnBoard
                isUploadCompleted={isUploadCompleted}
                resultData={isUploadCompleted ? getExceptionData : getOnBoardData}
              />
            </>
          )}

          {tabIndex === 1 && (
            <History
              filters={filter}
            />
          )}
        </Grid>
        {tabIndex === 1
      && (
      <Grid item style={{ paddingLeft: '10px', backgroundColor: '#F3F5F9' }} xs={2}>
        <RightPane
          history={historyData}
          setFilterData={setFilterData}
        />
      </Grid>
      )}
      </Grid>
    </>
  );
};

BoardingTemplatePage.defaultProps = {
  resultOperation: {},
};

BoardingTemplatePage.propTypes = {
  boardingPhaseOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  boardingTemplateHistory: PropTypes.shape().isRequired,
  caseTypeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeSweetAlert: PropTypes.func.isRequired,
  fetchBoardingTemplateHistory: PropTypes.func.isRequired,
  getExceptionData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getOnBoardData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getProcesseFiledData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // validateBoardingTemplateOnGo: PropTypes.func.isRequired,
  initiateImportProcessOperation: PropTypes.func.isRequired,
  onBoardTemplate: PropTypes.func.isRequired,
  openSweetAlert: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape({
    clearData: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    showConfirmButton: PropTypes.bool,
    status: PropTypes.string,
    title: PropTypes.string,
  }),
  setBoardingPhasesOperation: PropTypes.func.isRequired,
  setCaseTypesOperation: PropTypes.func.isRequired,
  validateBoardingTemplateOperation: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
  {
    resultOperation: selectors.resultOperation(state),
    boardingTemplateHistory: boardingTemplateSelectors.getBoardingTemplateHistory(state),
    getOnBoardData: boardingTemplateSelectors.getOnBoardData(state),
    getExceptionData: boardingTemplateSelectors.getExceptionData(state),
    getProcesseFiledData: boardingTemplateSelectors.getProcesseFiledData(state),
    caseTypeOptions: boardingTemplateSelectors.getCaseTypeOptions(state),
    boardingPhaseOptions: boardingTemplateSelectors.getBoardingPhases(state),
  }
);

const mapDispatchToProps = dispatch => ({
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
  fetchBoardingTemplateHistory: operations.fetchBoardingTemplateHistory(dispatch),
  validateBoardingTemplateOnGo: operations.validateBoardingTemplateOperation(dispatch),
  openSweetAlert: dashboardOperations.openSweetAlert(dispatch),
  onBoardTemplate: operations.onBoardTemplate(dispatch),
  validateBoardingTemplateOperation: operations.validateBoardingTemplateOperation(dispatch),
  initiateImportProcessOperation: operations.initiateImportProcessOperation(dispatch),
  setCaseTypesOperation: operations.setCaseTypesOperation(dispatch),
  setBoardingPhasesOperation: operations.setBoardingPhasesOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardingTemplatePage);
