import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import EvalCase from '../../containers/Eval/EvalCase';
import CaseDetailsComp from '../../containers/Eval/MODCaseDetails';
import RepaymentCaseScreen from '../../containers/Repayment/RepaymentCaseScreen';
import { CASE_DETAILS, REPAYMENT_PLAN_CASE } from '../../constants/loanInfoComponents';
import { operations as evalOperation } from '../../state/ducks/eval';
import { selectors as widgetsSelectors } from '../../state/ducks/widgets';
import { selectors as dashboardSelectors } from '../../state/ducks/dashboard';
import { EVAL } from '../../constants/widgets';

function EvalWidget(props) {
  const {
    checklistCenterPaneView, setEvalScreenIcon,
    openWidgetList, groupName,
  } = props;
  setEvalScreenIcon('evalWidget');

  if (R.contains(EVAL, openWidgetList) && groupName === 'SEARCH_LOAN') {
    return <EvalCase navFromEvalWidget />;
  }

  switch (checklistCenterPaneView) {
    case CASE_DETAILS:
      return <CaseDetailsComp />;
    case REPAYMENT_PLAN_CASE:
      return <RepaymentCaseScreen />;
    default:
      return <EvalCase navFromEvalWidget />;
  }
}

EvalWidget.defaultProps = {
  checklistCenterPaneView: 'Checklist',
  openWidgetList: [],
};

EvalWidget.propTypes = {
  checklistCenterPaneView: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  setEvalScreenIcon: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  groupName: dashboardSelectors.groupName(state),
});

const mapDispatchToProps = dispatch => ({
  setEvalScreenIcon: evalOperation.setEvalScreenIconOpn(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalWidget);
