import React, { useState, useEffect } from 'react';
import WaterFallSection from 'containers/Eval/WaterFallSection';
import NewCases from 'containers/Eval/NewCases';
import './EvalCase.css';
import * as R from 'ramda';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import { operations as evalOperations, selectors as evalSelector } from 'ducks/eval';
import { selectors as dashboardSelector } from 'ducks/dashboard';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import { selectors as loginSelectors } from 'ducks/login';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import { connect } from 'react-redux';
import {
  EVAL_STATUS_CASE_TYPES, COMPLETED, REJECTED,
} from '../../constants/eval';
import {
  FINANCIAL_CALCULATOR,
  LSAMS_NOTES,
} from '../../constants/widgets';
import { LOSS_MITIGATION, LOSS_MITIGATION_MGR } from '../../constants/Groups';
import IncomeCalcWidget from '../IncomeCalc/IncomeCalcWidget';

const EvalCase = (props) => {
  const {
    fetcEvalScreenInfo, evalScreenInfo, navFromEvalWidget,
    inProgress, openWidgetList, getModStatus, user,
  } = props;
  const [data, setData] = useState({});
  const [isWaterFallEditable, setIsWaterFallEditable] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const [mainSectionDisplay, setMainSectionDisplay] = useState(true);

  const handleMainSectionDisplay = () => {
    if (R.contains(LSAMS_NOTES, openWidgetList)
    || (R.contains(FINANCIAL_CALCULATOR, openWidgetList) && user.groupList
    && (user.groupList.includes(LOSS_MITIGATION)
    || user.groupList.includes(LOSS_MITIGATION_MGR)))) {
      setMainSectionDisplay('none');
    } else {
      setMainSectionDisplay('');
    }
  };

  useEffect(() => {
    fetcEvalScreenInfo();
  }, []);

  useEffect(() => {
    handleMainSectionDisplay();
  }, [openWidgetList]);

  useEffect(() => {
    if (!R.isEmpty(evalScreenInfo)) {
      setData(evalScreenInfo);
    }

    const evalStatus = R.pathOr('', ['evalCase', 'status'], evalScreenInfo);
    // const caseInfo = R.pathOr('', ['caseInfo'], evalScreenInfo);
    //  const currentCase = caseInfo && caseInfo.find(item => item.caseId === resolutionId);
    // const resolutionStatus = R.pathOr('', ['status'], currentCase);
    // Removing conditions to disable case detail screen as we are not calling here
    if (navFromEvalWidget) {
      if (evalStatus === COMPLETED || evalStatus === REJECTED) {
        setIsWaterFallEditable(false);
        setIsEditable(false);
      } else if (!EVAL_STATUS_CASE_TYPES.includes(evalStatus)) {
        setIsWaterFallEditable(false);
      } else {
        setIsWaterFallEditable(true);
      }
    }
  }, [evalScreenInfo]);

  return (
    R.isEmpty(data) || inProgress
      ? <Loader message="Please wait" size={60} />
      : (
        <div styleName="container">
          {R.contains(LSAMS_NOTES, openWidgetList) && <LSAMSNotesWidget />}
          {R.contains(FINANCIAL_CALCULATOR, openWidgetList) && user
          && (user.groupList.includes(LOSS_MITIGATION)
          || user.groupList.includes(LOSS_MITIGATION_MGR)) && <IncomeCalcWidget />}
          {/* <div style={{ display: R.contains(LSAMS_NOTES, openWidgetList) ? 'none' : '' }}> */}
          <div style={{ display: mainSectionDisplay }}>
            <WaterFallSection
              evalScreenData={data}
              isEditable={isEditable}
              isWaterFallEditable={isWaterFallEditable && !getModStatus}
            />
            <NewCases evalScreenData={data} isEditable={isWaterFallEditable && !getModStatus} />
          </div>
        </div>
      )
  );
};

EvalCase.defaultProps = {
  navFromEvalWidget: false,
  openWidgetList: [],
};

EvalCase.propTypes = {
  evalScreenInfo: PropTypes.shape().isRequired,
  fetcEvalScreenInfo: PropTypes.func.isRequired,
  getModStatus: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  navFromEvalWidget: PropTypes.bool,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  evalScreenInfo: evalSelector.getEvalScreenData(state),
  inProgress: evalSelector.inProgress(state),
  resolutionId: dashboardSelector.selectedResolutionId(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  getModStatus: dashboardSelector.getModStatus(state),
  user: loginSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
  fetcEvalScreenInfo: evalOperations.fetcEvalScreenInfoOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalCase);
