import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import OnBoardTable from '../components/Table/OnBoardTable';
import './Onboard.css';

const onBoard = ({ isUploadCompleted, resultData, inProgress }) => (
  <>
    <div>
      {
        inProgress ? (
          <CircularProgress styleName="loader" variant="indeterminate" />
        ) : (
          <OnBoardTable
            isUploadCompleted={isUploadCompleted}
            resultData={resultData}
          />
        )
      }
    </div>
  </>
);
onBoard.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  isUploadCompleted: PropTypes.bool.isRequired,
  resultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  inProgress: dashboardSelectors.inProgress(state),
});

export default connect(mapStateToProps)(onBoard);
