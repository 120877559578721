import React, { useState, useEffect, useMemo } from 'react';
import { Button, Divider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import './OnBoardTable.css';
import {
  FORMATTABLE_ONBOARD_TABLE_COLUMNS, ONBOARD_TABLE_COLUMNS,
  ONBOARD_TABLE_COLUMNS_MAPPING, EXCEPTION_TABLE_COLUMNS,
  EXCEPTION_TABLE_COLUMNS_MAPPING,
} from 'constants/BoardingTemplate';
import * as R from 'ramda';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import ExceptionPopup from '../HandleException/ExceptionPopup';

const useStyles = makeStyles(() => ({
  tableHeader: {
    color: 'grey',
    whiteSpace: 'nowrap',
    position: 'sticky',
    top: '0',
    zIndex: '1000',
    backgroundColor: '#F3F5F9',
  },
  greenLine: {
    borderLeft: '4px solid #3DCAA9',
  },
  redLine: {
    borderLeft: '4px solid #FD7171',
  },
  customTableContainer: {
    overflowX: 'initial',
    overflowY: 'auto',
    maxWidth: '100%',
    maxHeight: '500px',
  },
}));


const OnBoardTable = ({ isUploadCompleted, resultData }) => {
  const [open, setOpen] = useState(false);
  const [selectedErrorData, setSelectedErrorData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [successfulRecords, setSuccessfulRecords] = useState(0);
  const [unsuccessfulRecords, setUnsuccessfulRecords] = useState(0);
  const [criticalExceptions, setCriticalExceptions] = useState(0);
  const [mediumExceptions, setMediumExceptions] = useState(0);
  const [lowExceptions, setLowExceptions] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (resultData && resultData.length > 0) {
      setTotalRecords(resultData.length);
      setSuccessfulRecords(resultData.filter(record => record.success).length);
      setUnsuccessfulRecords(resultData.filter(record => !record.success).length);
      setCriticalExceptions(resultData.reduce((acc, record) => acc + (record['Low Severity Exceptions'] && record['Critical Severity Exceptions'].length > 0 ? 1 : 0), 0));
      setMediumExceptions(resultData.reduce((acc, record) => acc + (record['Medium Severity Exceptions'] && record['Medium Severity Exceptions'].length > 0 ? 1 : 0), 0));
      setLowExceptions(resultData.reduce((acc, record) => acc + (record['Low Severity Exceptions'] && record['Low Severity Exceptions'].length > 0 ? 1 : 0), 0));
    }
  }, [resultData]);

  const onboardHeaders = useMemo(() => ONBOARD_TABLE_COLUMNS, []);
  const exceptionHeaders = useMemo(() => EXCEPTION_TABLE_COLUMNS, []);

  const handleRowClick = (errorData) => {
    setSelectedErrorData(Array.isArray(errorData) ? errorData : [errorData]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!resultData || resultData.length === 0) {
    return <div>No data available</div>;
  }

  const headers = isUploadCompleted ? exceptionHeaders : onboardHeaders;

  function rowStyle(row) {
    if (isUploadCompleted) {
      return row.inFlight === 'true' ? classes.greenLine : classes.redLine;
    }
    return '';
  }

  const renderExceptions = (exceptions, color, handleClick) => {
    if (Array.isArray(exceptions) && exceptions.length > 0) {
      const firstTitle = exceptions[0].title;
      const maxLength = 25;
      let trimmedTitle = firstTitle;
      if (firstTitle.length > maxLength) {
        const lastSpaceIndex = firstTitle.lastIndexOf(' ', maxLength);
        if (lastSpaceIndex === -1) {
          trimmedTitle = `${firstTitle.substring(0, maxLength)}...`;
        } else {
          trimmedTitle = `${firstTitle.substring(0, lastSpaceIndex)}...`;
        }
      }

      return (
        <Button
          onClick={() => handleClick(exceptions)}
          style={{
            color,
            border: 'none',
            padding: '4px 8px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {trimmedTitle}
        </Button>
      );
    }
    return '-';
  };

  const getHeaderValue = (row, header) => {
    const headerObj = FORMATTABLE_ONBOARD_TABLE_COLUMNS[header];
    const headerValue = (headerObj && R.is(headerObj) && headerObj.cellFormat)
      ? headerObj.cellFormat(row[header]) : row[header];
    return headerValue;
  };

  const exceptionColors = {
    'Critical Severity Exceptions': '#F23563',
    'Medium Severity Exceptions': '#D77E15',
    'Low Severity Exceptions': '#1083B5',
  };
  return (
    <div styleName="container">
      <Divider style={{ marginBottom: '8px' }} />
      { isUploadCompleted && (
      <div styleName="summary">
        <div styleName="left">
          <div>
            {'Total Records: '}
            <span>{totalRecords || '-'}</span>
          </div>
          <div>
            {'Successful Records: '}
            <span>{successfulRecords || '-'}</span>
          </div>
          <div>
            {'Unsuccessful Records: '}
            <span>{unsuccessfulRecords || '-'}</span>
          </div>
        </div>
        <div styleName="right">
          <div>
            {'Critical Severity Exceptions: '}
            <span>{criticalExceptions || '-'}</span>
          </div>
          <div>
            {'Medium Severity Exceptions: '}
            <span>{mediumExceptions || '-'}</span>
          </div>
          <div>
            {'Low Severity Exceptions: '}
            <span>{lowExceptions || '-'}</span>
          </div>
        </div>
      </div>
      )
      }

      <div styleName="OnBoardContainer">
        <TableContainer
          className={classes.customTableContainer}
          style={{ scrollbarWidth: 'thin' }}
        >
          <Table
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header}>
                    {
                      isUploadCompleted ? EXCEPTION_TABLE_COLUMNS_MAPPING[header]
                        : ONBOARD_TABLE_COLUMNS_MAPPING[header]
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ whiteSpace: 'nowrap' }}>
              {resultData.map(row => (
                <TableRow
                  key={`row-${row['Loan Id']}`}
                  className={rowStyle(row)}
                >
                  {headers.map(header => (
                    <TableCell key={`${header}-${row[header]}`}>
                      {['Critical Severity Exceptions', 'Medium Severity Exceptions', 'Low Severity Exceptions'].includes(header)
                        ? renderExceptions(row[header], exceptionColors[header], handleRowClick)
                        : getHeaderValue(row, header)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Array.isArray(selectedErrorData) && selectedErrorData.length > 0 && (
      <ExceptionPopup isOpen={open} onClose={handleClose} selectedErrorData={selectedErrorData} />
      )}
    </div>
  );
};

OnBoardTable.propTypes = {
  isUploadCompleted: PropTypes.bool.isRequired,
  resultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default OnBoardTable;
