const COMMENTS = 'Comments';
const ADDITIONAL_INFO = 'Additional Info';
const HISTORY = 'History';
const CUSTOM_COMM_LETTER = 'Customer Communication Letter';
const BOOKING = 'BookingAutomation';
const FINANCIAL_CALCULATOR = 'FinancialCalculator';
const FHLMC = 'FHLMC';
const LSAMS_NOTES = 'LSAMS Notes';
const DOCUMENT_CHECKLIST = 'DocumentChecklist';
const EVAL = 'Eval Info';
const WESTWING = 'lossmitigation';
const WESTWINGWIDGET = 'WESTWINGWIDGET';
const EVAL_SCRN = 'EVAL_SCRN';
const PAYMENT_SUPPLEMENT = 'Payment Supplement';
const SMDU = 'SMDU';
const FNMA = 'FNMA';
const SMDU_RESL_CHOICE_TYPES = ['Flex Modification', 'Flex Modification Trial', 'Payment Deferral', 'Payment Deferral Disaster'];

const SMDU_OPERATION_TYPE = {
  'Pre Qualify': 1,
  'Trial Case': 2,
  'Mod Case': 3,
  'Run IDI': 5,
};

const REQUEST_TYPE_MAP = {
  'Structuring-Final': 'Mod Case',
  'Structuring-Trial': 'Trial Case',
  'Pre-Qualification': 'Pre Qualify',
  'Imminent Default': 'Run Idi',
};

const FAILED_STATUS_CODES = [400, 404, 422, 500];
const PRIOR_WORKOUT_SUCCESS = 'Prior Workout Data Saved Successfully!';
const SUBMIT_FNMA_SUCCESS = 'Successfully Submitted to FNMA !';

const widgets = [
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  FHLMC,
  LSAMS_NOTES,
  DOCUMENT_CHECKLIST,
  EVAL,
  WESTWING,
  WESTWINGWIDGET,
  EVAL_SCRN,
  PAYMENT_SUPPLEMENT,
  SMDU,
  FNMA,
];

const FAIL_CANCEL_REASONS = [
  { id: 1, reason: 'Ineligible Mortgage' },
  { id: 2, reason: 'Ineligible Borrower - Current HTI Less than 31%' },
  { id: 3, reason: 'Property Not Owner Occupied' },
  { id: 4, reason: 'Other Ineligible Property (i.e. Property Condemned, Property > 4 units)' },
  { id: 5, reason: 'Investor Guarantor Not Participating' },
  { id: 6, reason: 'B/K Court Declined' },
  { id: 7, reason: 'Negative NPV' },
  { id: 8, reason: 'Offer Not Accepted by Borrower / Request Withdrawn' },
  { id: 9, reason: 'Default Not Imminent' },
  { id: 10, reason: 'Previous Official HAMP Modification' },
  { id: 11, reason: 'Loan Paid off or Reinstated' },
  { id: 12, reason: 'Excessive Forbearance' },
  { id: 13, reason: 'Request Incomplete' },
  { id: 14, reason: 'Payment Plan Default' },
  { id: 15, reason: 'Unemployment Forbearance Plan' },
  { id: 16, reason: 'Income Documentation Not Provided' },
  { id: 17, reason: 'Income Misrepresented' },
  { id: 20, reason: 'Federally Declared Disaster' },
  { id: 21, reason: 'Borrower Failed to Respond to Solicitation' },
  { id: 22, reason: 'Data Incorrect' },
  { id: 23, reason: 'Other' },
  { id: 24, reason: 'Duplicate/Intend to Resubmit' },
  { id: 25, reason: 'Trial Period Plan Payment Default' },
  { id: 26, reason: 'Borrower Did Not Sign Required Modification Documents' },
  { id: 27, reason: 'Borrower Converted to another Workout' },
  { id: 28, reason: 'Borrower No Longer Eligible' },
  { id: 29, reason: 'Perm Mod Docs Not Returned' },
];

const WORKOUT_STATUS_TYPES = [
  { label: 'Evaluated-Not Qualified', value: 1 },
  { label: 'Evaluated-Qualified', value: 2 },
  { label: 'Offered to Borrower', value: 3 },
  { label: 'Accepted by Borrower', value: 4 },
  { label: 'Provisional Payment Period', value: 5 },
  { label: 'Permanent / Completed Workout', value: 6 },
];


const ACTIVITY_TYPES = [
  { label: 'Active', value: 'A' },
  { label: 'Complete', value: 'C' },
  { label: 'Failed / Cancelled / Denied', value: 'F' },
  { label: 'Inactive', value: 'I' },
];

export {
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  widgets,
  FHLMC,
  LSAMS_NOTES,
  DOCUMENT_CHECKLIST,
  EVAL,
  WESTWING,
  WESTWINGWIDGET,
  EVAL_SCRN,
  PAYMENT_SUPPLEMENT,
  SMDU,
  SMDU_OPERATION_TYPE,
  REQUEST_TYPE_MAP,
  FAILED_STATUS_CODES,
  FNMA,
  PRIOR_WORKOUT_SUCCESS,
  SUBMIT_FNMA_SUCCESS,
  SMDU_RESL_CHOICE_TYPES,
  FAIL_CANCEL_REASONS,
  WORKOUT_STATUS_TYPES,
  ACTIVITY_TYPES,
};
