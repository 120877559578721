import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import * as R from 'ramda';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import EvalTable from '../../../components/Eval/EvalTable/index';
import Loader from '../../../components/Loader';
import './Amortization.css';
import { selectors } from '../../../state/ducks/eval';


const Amortization = ({ amortizationData, inProgress }) => {
  const [footerData, setFooterData] = useState([]);
  const [amortizationDetails, setAmortizationDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAmortizationDetails(amortizationData);
    setLoading(false);
  }, [amortizationData]);
  const getFooterData = () => {
    const newObject = {
      interest: 0,
      principal: 0,
      balloon: 0,
      total: 0,
    };
    if (amortizationDetails && amortizationDetails.length > 0) {
      amortizationDetails.forEach(
        (obj) => {
          newObject.interest += obj.interest;
          newObject.principal += obj.principal;
          newObject.balloon += obj.balloon;
          newObject.total += obj.total;
        },
      );
    }
    return {
      date: 'Total',
      rate: '',
      interest: `$${newObject.interest.toFixed(2)}`,
      principal: `$${newObject.principal.toFixed(2)}`,
      balloon: `$${newObject.balloon.toFixed(2)}`,
      total: `$${newObject.total.toFixed(2)}`,
      type: '',
      pv: '',
    };
  };
  useEffect(() => {
    setFooterData(getFooterData());
  }, [amortizationDetails]);
  let amortizationTemplateRef = useRef(null);
  const getTableData = () => {
    const amData = amortizationDetails.map(d => R.omit(['result', 'loanId', 'brandId', 'relatedBrands', 'loanIdString', 'environment', 'logInfo', 'logEventId', 'npvAt9Pct'], d));
    const {
      interest, principal, balloon, total,
    } = footerData;
    return [...amData, {
      date: 'Total',
      rate: '',
      interest,
      principal,
      balloon,
      total,
    }];
  };

  const AMORTIZATION_TABLE_COLUMNS = [
    {
      name: 'date',
      label: 'Date',
      align: 'left',
      cellFormat: item => moment(item).format('DD MMM YYYY'),
    },
    {
      name: 'rate',
      label: 'Rate (%)',
      align: 'left',
      cellFormat: item => `${Number.isNaN(Number(item)) ? 0 : (Number(item) * 100).toFixed(3)}%`,
    },
    {
      name: 'interest',
      label: 'Interest',
      align: 'left',
      cellFormat: item => `$${Number.isNaN(Number(item)) ? 0 : Number(item).toFixed(2)}`,
    },
    {
      name: 'principal',
      label: 'Principal',
      align: 'left',
      cellFormat: item => `$${Number.isNaN(Number(item)) ? 0 : Number(item).toFixed(2)}`,
    },
    {
      name: 'balloon',
      label: 'Balloon',
      align: 'left',
      cellFormat: item => `$${Number.isNaN(Number(item)) ? 0 : Number(item).toFixed(2)}`,
    },
    {
      name: 'total',
      label: 'Total',
      align: 'left',
      cellFormat: item => `$${Number.isNaN(Number(item)) ? 0 : Number(item).toFixed(2)}`,
    },
    {
      name: 'type',
      label: 'Type',
      align: 'left',
    },
    {
      name: 'pv',
      label: 'PV',
      align: 'left',
      cellFormat: item => `$${Number.isNaN(Number(item)) ? 0 : Number(item).toFixed(2)}`,
    },
  ];

  const onDownloadCSV = () => {
    amortizationTemplateRef.link.click();
  };
  return (
    <>
      {loading || inProgress ? <Loader message="Please Wait" />
        : (
          <>
            {(amortizationDetails && amortizationDetails.length > 0) && (
              <div styleName="download-btn">
                <Button onClick={() => onDownloadCSV()} variant="text">
                  <DownloadIcon styleName="download-icon" />
                  <CSVLink
                    // eslint-disable-next-line no-return-assign
                    ref={event => amortizationTemplateRef = event}
                    data={getTableData()}
                    filename="amortization.csv"
                    onClick={event => event.stopPropagation()}
                    styleName="download-icon-link"
                  >
                    Download
                  </CSVLink>
                </Button>
              </div>
            )}
            <div>
              <EvalTable
                columns={AMORTIZATION_TABLE_COLUMNS}
                data={amortizationDetails}
                footerData={footerData}
                height="50vh"
              />
            </div>
          </>
        )}
    </>
  );
};

Amortization.defaultProps = {
  amortizationData: [],
};
Amortization.propTypes = {
  amortizationData: PropTypes.arrayOf(PropTypes.shape()),
  inProgress: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  amortizationData: selectors.amortizationDetails(state),
  inProgress: selectors.isCalculateLoading(state),
});

export default connect(mapStateToProps, null)(Amortization);
