import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import './SendForRejectButton.css';


const SendForRejectButton = (props) => {
  const {
    title,
    onClick,
    disabled,
    ...other
  } = props;


  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      styleName="details-table-btn"
      {...other}
    >
      {title}
    </Button>
  );
};

SendForRejectButton.defaultProps = {
  onClick: () => { },
  disabled: () => {},
  additionalInfo: {},
};

SendForRejectButton.propTypes = {
  additionalInfo: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default SendForRejectButton;
