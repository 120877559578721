import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { PropTypes } from 'prop-types';
import '../BoardingTemplatePage.css';
import * as R from 'ramda';

const PreApprovedToggleSwitch = ({
  preApprovedStatus, setPreApprovedStatus, inFlightStatus, setIsSubmitDisabled,
  loanIds, selectedCaseType, selectedPhase,
}) => {
  const handleToggle = () => {
    setPreApprovedStatus(!preApprovedStatus);
  };

  useEffect(() => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    if (loanIds === '' || !re.test(loanIds)) {
      setIsSubmitDisabled(!(loanIds.trim()
      && !R.isEmpty(selectedPhase) && !R.isEmpty(selectedCaseType)
      && (inFlightStatus || preApprovedStatus)
      ));
    }
  }, [preApprovedStatus]);
  return (
    <>
      <div styleName="loan-numbers">
        <span styleName="typography">
          {'Pre Approved'}
        </span>
      </div>
      <Grid alignItems="center" component="label" container>
        <Grid item>
          <Switch
            checked={preApprovedStatus}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name="toggle"
            onChange={handleToggle}
          />
        </Grid>
      </Grid>
    </>
  );
};

PreApprovedToggleSwitch.propTypes = {
  inFlightStatus: PropTypes.bool.isRequired,
  loanIds: PropTypes.string.isRequired,
  preApprovedStatus: PropTypes.bool.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setPreApprovedStatus: PropTypes.func.isRequired,

};

export default PreApprovedToggleSwitch;
